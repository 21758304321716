export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoSection = {
  // popularGames: {
  //   UltimateAndarBahar: {
  //     redirectUrl: "/casino/ezg-ultimate-andar-bahar",
  //     code: "328000",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/ultimateAB.jpg",
  //     alt: "",
  //     name: "Ultimate Andar Bahar",
  //     providerName: "Ezugi",
  //   },
  //   AndarBahar: {
  //     redirectUrl: "/casino/ezg-andar-bahar",
  //     code: "328000",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/andarbahar.jpg",
  //     alt: "",
  //     name: "Andar Bahar",
  //     providerName: "Ezugi",
  //   },
  //   aviator: {
  //     redirectUrl: "/casino/spribe/aviator",
  //     code: "aviator",
  //     casino: "spribe",
  //     provider: "aviator",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/spribe/Aviator.gif",
  //     alt: "",
  //     name: "Aviator",
  //     providerName: "Spribe",
  //   },
  //   AutoRoulette: {
  //     redirectUrl: "/casino/ezg-auto-roulette",
  //     code: "1000148",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/autoroulette.jpg",
  //     alt: "",
  //     name: "Auto Roulette",
  //     providerName: "Evolution",
  //   },
  //   SpeedRoulette: {
  //     redirectUrl: "/casino/ezgevo-speed-roulette",
  //     code: "1000148",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/Speedroulette.jpg",
  //     alt: "",
  //     name: "Speed Roulette",
  //     providerName: "Evolution",
  //   },
  //   LightningRoulette: {
  //     redirectUrl: "/casino/ezgevo-lightning-roulette",
  //     code: "1000148",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/lightningroulette-.jpg",
  //     alt: "",
  //     name: "Lightning Roulette",
  //     providerName: "Evolution",
  //   },
  //   DragonTiger: {
  //     redirectUrl: "/casino/ezg-dragon-tiger",
  //     code: "1000148",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/Dragontigerr.jpg",
  //     alt: "",
  //     name: "Dragon Tiger",
  //     providerName: "Evolution",
  //   },
  //   Lucky7: {
  //     redirectUrl: "/casino/ezg-lucky-7",
  //     code: "227103",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/lucky.jpg",
  //     alt: "",
  //     name: "Lucky 7",
  //     providerName: "Ezugi",
  //   },
  //   CricketWar: {
  //     redirectUrl: "/casino/ezg-cricket-war",
  //     code: "227103",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/CricketWar.jpg",
  //     alt: "",
  //     name: "Cricket War",
  //     providerName: "Ezugi",
  //   },
  //   CrazyTime: {
  //     redirectUrl: "/casino/ezugi/CrazyTime",
  //     code: "1000148",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/CrazyTime.jpg",
  //     alt: "",
  //     name: "Crazy Time",
  //     providerName: "Evolution",
  //   },
  // },

  roulettetab: {
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedautoroullete.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Namasteroulette.jpg",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    PrestigeAutoRoulette: {
      redirectUrl: "/casino/ezg-prestige-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Prestige-Auto-Roulette.jpg",
      alt: "",
      name: "Prestige Auto Roulette",
      providerName: "Ezugi",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/DiamondRoulette.jpg",
      alt: "",
      name: "Diamond Roulette",
      providerName: "Ezugi",
    },
    ItalianRoulette: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/ItalianRoulette.jpg",
      alt: "",
      name: "Italian Roulette",
      providerName: "Ezugi",
    },
    RuletkaRussiaRoulette: {
      redirectUrl: "/casino/ez-ruletka-russia",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Ruletka-Russia.jpg",
      alt: "",
      name: "Ruletka Russia Roulette",
      providerName: "Ezugi",
    },
    TurkishRoulette: {
      redirectUrl: "/casino/ezg-turkish-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Turkce-Rulet.jpg",
      alt: "",
      name: "Turkish Roulette",
      providerName: "Ezugi",
    },

    SpeedRoulette: {
      redirectUrl: "/casino/ezgevo-speed-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/speed-roulette-evolution.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Evolution",
    },
    FirstPersonXXXTremeRoulette: {
      redirectUrl: "/casino/first-person-xxxtreme-lightning-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/FirstPersonXxxtremeLightningRoulette.jpg",
      alt: "",
      name: "First Person Xxxtreme Lightning Roulette",
      providerName: "Evolution",
    },
    ImmersiveRoulette: {
      redirectUrl: "/casino/ezgevo-immersive-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Immersive.jpg",
      alt: "",
      name: "Immersive Roulette",
      providerName: "Evolution",
    },
    AutoRouletteVIP: {
      redirectUrl: "/casino/ezgevo-auto-roulette-vip",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AutoRouletteVIP.jpg",
      alt: "",
      name: "Auto Roulette VIP",
      providerName: "Evolution",
    },
    AmericanRoulette: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AmericanRoulette.jpg",
      alt: "",
      name: "American Roulette",
      providerName: "Evolution",
    },
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/lightningroulette-.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolution",
    },

    PortomasoRoulette: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      code: "1000124",
      casino: "Vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
      alt: "",
      name: "Portomaso Roulette",
      providerName: "Vivo",
    },
    OracleBlazeRoulette: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      code: "1000102",
      casino: "Vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
      name: "Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    Oracle360Roulette: {
      redirectUrl: "/casino/vivo-oracle-360-roulette",
      code: "1000312",
      casino: "Vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/Oracle360Roulette.jpg",
      name: "Oracle 360 Roulette",
      providerName: "Vivo",
    },
    EuropeanAutoRoulette: {
      redirectUrl: "/casino/vivo-european-auto-roulette",
      code: "1000120",
      casino: "ezugi",
      provider: "Vivo",
      homeUrl: "",
      imgUrl: "./images/Vivo/EuropeanAutoRoulette.jpg",

      name: "European Auto Roulette",
      providerName: "Vivo",
    },
    FrenchRoulette: {
      redirectUrl: "/casino/vivo-french-roulette",
      code: "1000092",
      casino: "Vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/FrenchRoulette.jpg",
      name: "French Rulette",
      providerName: "Vivo",
    },
    LasVegasroulette: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      code: 1000084,
      casino: "Vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/LasVegasRoulette.jpg",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
    VARoulette: {
      redirectUrl: "/casino/vivo-va-roulette",
      code: "1000120",
      casino: "ezugi",
      provider: "Vivo",
      homeUrl: "",
      imgUrl: "./images/Vivo/VARoulette.jpg",
      providerName: "Vivo",
      name: "VA Roulette",
    },
    GalaxyRoulette: {
      redirectUrl: "/casino/vivo-galaxy-roulette",
      code: "1000120",
      casino: "Vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/GalaxyRoulette.jpg",
      providerName: "Vivo",
      name: "Galaxy Roulette",
    },
  },

  pokertab: {
    BetOnTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.jpg",
      alt: "",
      name: "Bet On Teen Patti",
      providerName: "Ezugi",
    },
    OneDayTeenPatti: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    TeenPatti3Card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.jpg",
      alt: "",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    CasinoHoldem: {
      redirectUrl: "/casino/ezg-casino-holdem",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/CasinoHoldemEzg.jpg",
      alt: "",
      name: "Casino Hold'em",
      providerName: "Ezugi",
    },
    RoyalPoker: {
      redirectUrl: "/casino/ezg-royal-poker",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/RussianPoker.jpg",
      alt: "",
      name: "Russian Poker",
      providerName: "Ezugi",
    },

    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezugi/Hand2CasinoHoldem",
      code: "1000073",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholdem.jpg",
      alt: "",
      name: " 2 Hand Casino Holdem",
      providerName: "Evolution",
    },
    TexasHoldemBonusPokerEvo: {
      redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
      code: "1000111",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/TexasHoldemBonusPoker.jpg",
      alt: "",
      name: "Texas Hold'em Bonus Poker",
      providerName: "Evolution",
    },
    UltimatetexasholdemEvo: {
      redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
      code: "1000151",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/ultimatetaxasholdem.jpg",
      alt: "",
      name: "Ultimate Texas Hold'em",
      providerName: "Evolution",
    },
    ThreeCardPokerEvo: {
      redirectUrl: "/casino/ezgevo-three-card-poker",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/ThreeCardPoker.jpg",
      alt: "",
      name: "Three Card Poker",
      providerName: "Evolution",
    },

    vivoTeenpatti: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/TeenPatti.jpg",
      alt: "",
      name: "Teen Patti ",
      providerName: "Vivo",
    },
    VivoCasinoHoldem: {
      href: "/casino/vivo-casino-holdem",
      code: "227103",
      casino: "ezugi",
      name: "texas hold'em bonus poker",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/CasinoHoldem.jpg",
      name: "Casino Hold'em",
      providerName: "Vivo",
    },
  },

  baccarattab: {
    NoCommissionBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/no-commission-baccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/DragonTigerrEzg.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Cricket-War.jpg",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat08.jpg",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Fortunebaccarat.png",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    Super6Baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat04.jpg",
      alt: "",
      name: "Super 6 Baccarat",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-speed-cricket-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
      alt: "",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
    },
    SpeedFortuneBaccarat: {
      redirectUrl: "/casino/ezg-speed-fortune-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/speed-fortune-baccarta.jpg",
      alt: "",
      name: "Speed Fortune Baccarat",
      providerName: "Ezugi",
    },
    KnockoutBaccarat: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/baccarat03.jpg",
      alt: "",
      name: "Knockout Baccarat",
      providerName: "Ezugi",
    },
    FiestaBaccarat: {
      redirectUrl: "/casino/ezg-fiesta-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/FiestaBaccarat.jpg",
      alt: "",
      name: "Fiesta Baccarat",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat1: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/CasinoMarinaBaccarat1.jpg",
      alt: "",
      name: "Casino Marina Baccarat 1",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat2: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/CasinoMarinaBaccarat2.jpg",
      alt: "",
      name: "Casino Marina Baccarat 2",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat3: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/CasinoMarinaBaccarat3.jpg",
      alt: "",
      name: "Casino Marina Baccarat 3",
      providerName: "Ezugi",
    },
    CasinoMarinaBaccarat4: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/CasinoMarinaBaccarat4.jpg",
      alt: "",
      name: "Casino Marina Baccarat 4",
      providerName: "Ezugi",
    },
    VIPNoCommissionSpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-vip-no-commission-speed-cricket-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/VIPNoCommissionSpeedCricketBaccarat.jpg",
      alt: "",
      name: "VIP No Commission Speed Cricket Baccarat",
      providerName: "Ezugi",
    },

    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/DragonTigerEvo.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },

    MacauBaccarat: {
      href: "/casino/vivo-macau-baccarat",
      code: "1000145",
      casino: "Vivo",
      provider: "EVO_LOBBY",
      homeUrl: "",
      imgUrl: "./images/Vivo/MacauBaccarat.jpg",
      name: "MacauBaccarat",
      providerName: "Vivo",
    },
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
    GalaxyBaccarat1: {
      href: "/casino/vivo-galaxy-baccarat-1",
      code: "1000012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/GalaxyBaccarat1.jpg",
      name: "Galaxy Baccarat 1",
      providerName: "Vivo",
    },
  },

  andarBahar: {
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/AndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/UltimateAndarBahar.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    CasinoMarinaAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/CasinoMarinaAndarBahar.jpg",
      alt: "",
      name: "Casino Marina Andar Bahar",
      providerName: "Ezugi",
    },

    VivoAndarBahar: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/Vivo/AndarBahar.jpg",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
  },

  lucky7: {
    lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/lucky7/Lucky7.jpg",
      alt: "",
      name: "lucky7",
      providerName: "Ezugi",
    },
  },

  dicegames: {
    SicBo: {
      redirectUrl: "/casino/ezg-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/SicBo.jpg",
      alt: "",
      name: "Sic Bo",
      providerName: "Ezugi",
    },
    UltimateSicBo: {
      redirectUrl: "/casino/ezg-ultimate-sic-bo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/UltimateSicBo.jpg",
      alt: "",
      name: "Ultimate Sic Bo",
      providerName: "Ezugi",
    },

    LightningDiceEvo: {
      redirectUrl: "/casino/ezg-lightning-dice",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/LightningDice.jpg",
      alt: "",
      name: "Lightning Dice",
      providerName: "Evolution",
    },
    SuperSicBoEvo: {
      redirectUrl: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/SuperSicBo.jpg",
      alt: "",
      name: "Super Sic Bo",
      providerName: "Evolution",
    },
    CrapsEvo: {
      redirectUrl: "/casino/ezgevo-craps",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/Craps.jpg",
      alt: "",
      name: "Craps",
      providerName: "Evolution",
    },
    FirstPersonCrapsEvo: {
      redirectUrl: "/casino/ezgevo-first-person-craps",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/FirstPersonCraps.jpg",
      alt: "",
      name: "First Person Craps",
      providerName: "Evolution",
    },
    BacBoEvo: {
      redirectUrl: "/casino/ezgevo-bac-bo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/BacBo.jpg",
      alt: "",
      name: "Bac Bo",
      providerName: "Evolution",
    },
  },

  cards32: {
    Cards32: {
      redirectUrl: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/card/32Cards.jpg",
      alt: "",
      name: "32 Cards",
      providerName: "Ezugi",
    },
  },

  blackjacktab: {
    GoldBlackjack4: {
      redirectUrl: "/casino/ezg-gold-blackjack-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjackgold4.jpg",
      alt: "",
      name: "Gold Blackjack 4",
      providerName: "Ezugi",
    },
    GoldBlackjack5: {
      redirectUrl: "/casino/ezg-gold-blackjack-5",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/blackjackgold5.jpg",
      alt: "",
      name: "Gold Blackjack 5",
      providerName: "Ezugi",
    },
    PlatinumBlackjack1: {
      redirectUrl: "/casino/ez-blackjack-platinum-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/PlatinumBlackjack1.jpg",
      alt: "",
      name: "Platinum Blackjack 1",
      providerName: "Ezugi",
    },
    Blackjack1: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack01.jpg",
      alt: "",
      name: "Blackjack 1",
      providerName: "Ezugi",
    },
    GoldBlackjack3: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
      alt: "",
      name: "Gold Blackjack 3",
      providerName: "Ezugi",
    },
    Blackjack7: {
      redirectUrl: "/casino/ezg-blackjack-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack7.jpg",
      alt: "",
      name: "Blackjack 7",
      providerName: "Ezugi",
    },
    UnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/UnlimitedBlackjack.jpg",
      alt: "",
      name: "Unlimited Blackjack",
      providerName: "Ezugi",
    },
    RussianBlackjack1: {
      redirectUrl: "/casino/ezg-Russian-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RussianBlackjack1.jpg",
      alt: "",
      name: "Russian Blackjack 1",
      providerName: "Ezugi",
    },
    RumbaBlackjack1: {
      redirectUrl: "/casino/ezg-rumba-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RumbaBlackjack1.jpg",
      alt: "",
      name: "Rumba Blackjack 1",
      providerName: "Ezugi",
    },
    VIPDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/DiamondVIPBlackjack.jpg",
      alt: "",
      name: "VIP Diamond Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack4: {
      redirectUrl: "/casino/ezg-rumba-blackjack-4",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RumbaBlackjack4.jpg",
      alt: "",
      name: "Rumba Blackjack 4",
      providerName: "Ezugi",
    },
    ItalianBlackjack: {
      redirectUrl: "/casino/ezg-Italian-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/ItalianBlackjack.jpg",
      alt: "",
      name: "Italian Blackjack",
      providerName: "Ezugi",
    },
    TurkishBlackjack1: {
      redirectUrl: "/casino/ezg-turkish-blackjack-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Turkishblackjack.jpg",
      alt: "",
      name: "Turkish Blackjack 1",
      providerName: "Ezugi",
    },
    TurkishUnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-turkish-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/TurkishUnlimitedBlackjack.jpg",
      alt: "",
      name: "Turkish Unlimited Blackjack",
      providerName: "Ezugi",
    },
    FiestaUnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-fiesta-unlimited-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/FiestaUnlimitedBlackjack.jpg",
      alt: "",
      name: "Fiesta Unlimited Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack2: {
      redirectUrl: "/casino/ez-rumba-blackjack-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RumbaBlackjack2.jpg",
      alt: "",
      name: "Rumba Blackjack 2",
      providerName: "Ezugi",
    },
    RussianBlackjack2: {
      redirectUrl: "/casino/ezg-Russian-blackjack-2",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RussianBlackjack2.jpg",
      alt: "",
      name: "Russian Blackjack 2",
      providerName: "Ezugi",
    },
    SalonPriveBlackjack: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/SalonPriveBlackjack.jpg",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Ezugi",
    },
    VIPSurrenderBlackjack: {
      redirectUrl: "/casino/ezg-vip-surrender-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/VIPblackjackwithsurrender.jpg",
      alt: "",
      name: "VIP Surrender Blackjack",
      providerName: "Ezugi",
    },
    RumbaBlackjack3: {
      redirectUrl: "/casino/ez-rumba-blackjack-3",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RumbaBlackjack3.jpg",
      alt: "",
      name: "Rumba Blackjack 3",
      providerName: "Ezugi",
    },
    RomanianBlackjack: {
      redirectUrl: "/casino/ezg-romanian-blackjack",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/RomanianBlackjack.jpg",
      alt: "",
      name: "Romanian Blackjack",
      providerName: "Ezugi",
    },

    BlackjackC: {
      redirectUrl: "/casino/ezgevo-blackjack-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackC.jpg",
      alt: "",
      name: "Blackjack C",
      providerName: "Evolution",
    },
    BlackjackParty: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackParty.jpg",
      alt: "",
      name: "Blackjack Party",
      providerName: "Evolution",
    },
    BlackjackSilverA: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-a",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverA.jpg",
      alt: "",
      name: "Blackjack Silver A",
      providerName: "Evolution",
    },
    BlackjackSilverB: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-b",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverB.jpg",
      alt: "",
      name: "Blackjack Silver B",
      providerName: "Evolution",
    },
    BlackjackSilverC: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-c",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverC.jpg",
      alt: "",
      name: "Blackjack Silver C",
      providerName: "Evolution",
    },
    BlackjackSilverD: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-d",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverD.jpg",
      alt: "",
      name: "Blackjack Silver D",
      providerName: "Evolution",
    },
    BlackjackSilverE: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-e",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverE.jpg",
      alt: "",
      name: "Blackjack Silver E",
      providerName: "Evolution",
    },
    BlackjackSilverF: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-f",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverF.jpg",
      alt: "",
      name: "Blackjack Silver F",
      providerName: "Evolution",
    },
    BlackjackSilverG: {
      redirectUrl: "/casino/ezgevo-blackjack-Silver-g",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackSilverG.jpg",
      alt: "",
      name: "Blackjack Silver G",
      providerName: "Evolution",
    },
    BlackjackVIP1: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-1",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackVIP1.jpg",
      alt: "",
      name: "Blackjack VIP 1",
      providerName: "Evolution",
    },
    BlackjackVIP2: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-2",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackVIP2.jpg",
      alt: "",
      name: "Blackjack VIP 2",
      providerName: "Evolution",
    },
    BlackjackVIP11: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-11",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/BlackjackVIP11.jpg",
      alt: "",
      name: "Blackjack VIP 11",
      providerName: "Evolution",
    },

    LimitlessBlackjack: {
      redirectUrl: "/casino/vivo-limitless-blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Limitless Blackjack	",
      imgUrl: "./images/Vivo/LimitLessBlackjack.jpg",
      providerName: "Vivo",
    },
    BlackjackVip: {
      redirectUrl: "/casino/platipus-blackjack-vip",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack Vip",
      imgUrl: "./images/Vivo/BlackjackVip.jpg",
      providerName: "Vivo",
    },
    LasVegasBlackjack: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Las Vegas Blackjack",
      imgUrl: "./images/Vivo/LasVegasBlackjack.jpg",
      providerName: "Vivo",
    },
    BlackjackVivo: {
      redirectUrl: "/casino/vivo-blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Blackjack ",
      imgUrl: "./images/Vivo/BlackjackVivo.jpg",
      providerName: "Vivo",
    },
  },

  gameShows: {
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/DragonTigerrEzg.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },

    DreamCatcher: {
      redirectUrl: "/casino/ezgevo-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/DreamCatcher.jpg",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    FirstPersonDreamCatcher: {
      redirectUrl: "/casino/ezgevo-first-person-dream-catcher",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/FirstPersonDreamCatcher.jpg",
      alt: "",
      name: "Dream Catcher",
      providerName: "Evolution",
    },
    MegaBall: {
      redirectUrl: "/casino/ezgevo-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/MegaBall.jpg",
      alt: "",
      name: "Mega Ball",
      providerName: "Evolution",
    },
    FirstPersonMegaBall: {
      redirectUrl: "/casino/ezgevo-first-person-mega-ball",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/FirstPersonMegaBall.jpg",
      alt: "",
      name: "First Person Mega Ball",
      providerName: "Evolution",
    },
    SideBetCity: {
      redirectUrl: "/casino/ezgevo-side-bet-city",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/SideBetCity.jpg",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
    },
    FootballStudio: {
      redirectUrl: "/casino/ezgevo-football-studio",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/FootballStudio.jpg",
      alt: "",
      name: "Football Studio",
      providerName: "Evolution",
    },
    FirstPersonTopCard: {
      redirectUrl: "/casino/ezgevo-first-person-top-card",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/FirstPersonTopCard.jpg",
      alt: "",
      name: "First Person Top Card",
      providerName: "Evolution",
    },
    GonzosTreasureHunt: {
      redirectUrl: "/casino/ezgevo-gonzos-treasure-hunt",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/GonzosTreasureHunt.jpg",
      alt: "",
      name: "Gonzo's Treasure Hunt",
      providerName: "Evolution",
    },
    CashOrCrash: {
      redirectUrl: "/casino/ezgevo-cash-or-crash",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/CashorCrash.jpg",
      alt: "",
      name: "Cash or Crash",
      providerName: "Evolution",
    },
    BacBo: {
      redirectUrl: "/casino/ezgevo-bac-bo",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dice/BacBo.jpg",
      alt: "",
      name: "Bac Bo",
      providerName: "Evolution",
    },
    ExtraChilliEpicSpins: {
      redirectUrl: "/casino/ezgevo-extra-chilli-epic-spins",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/ExtraChilliEpicSpins.jpg",
      alt: "",
      name: "Extra Chilli Epic Spins",
      providerName: "Evolution",
    },
    CrazyTime: {
      redirectUrl: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/CrazyTime.jpg",
      alt: "",
      name: "Crazy Time",
      providerName: "Evolution",
    },
    FirstPersonDragonTiger: {
      redirectUrl: "/casino/ezgevo-first-person-dragon-tiger",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/FirstPersonDragonTiger.jpg",
      alt: "",
      name: "First Person Dragon Tiger",
      providerName: "Evolution",
    },
    MonopolyBigBaller: {
      redirectUrl: "/casino/evo-monopoly-big-baller",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/MonopolyBigBaller.jpg",
      alt: "",
      name: "MONOPOLY Big Baller",
      providerName: "Evolution",
    },
    MonopolyLive: {
      redirectUrl: "/casino/evo-monopoly-live",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/gameshows/MonopolyLive.jpg",
      alt: "",
      name: "MONOPOLY Live",
      providerName: "Evolution",
    },
  },

  aviator: {
    Aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-aviator.png",
      alt: "aviator",
      name: "Aviator",
      providerName: "Spribe",
    },
  },

  turbo: {
    Dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-dice.png",
      alt: "dice",
      name: "Dice",
      providerName: "Spribe",
    },
    Plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-plinko.png",
      alt: "plinko",
      name: "Plinko",
      providerName: "Spribe",
    },
    Goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-goal.png",
      alt: "goal",
      name: "Goal",
      providerName: "Spribe",
    },
    Hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-hilo.png",
      alt: "hilo",
      name: "Hi-lo",
      providerName: "Spribe",
    },
    Mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-mines.png",
      alt: "mines",
      name: "Mines",
      providerName: "Spribe",
    },
    MiniRoulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "",
      casino: "spribe",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/spribe-miniroulette.png",
      alt: "mini roulette",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
  },

  dragontiger: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerr.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    DragonTigerEvolution: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/tvshows/Dragontigerrevo.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Evolution",
    },
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    RNGCricket2020: {
      redirectUrl: "/casino/sn-rng-cricket-2020",
      code: "RCKT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCricket2020.jpg",
      alt: "",
      name: "RNG Cricket 2020",
      providerName: "Supernowa",
    },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.jpg",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.jpg",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.jpg",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
  },

  // vivo: {
  //   VABacarat1: {
  //     redirectUrl: "/casino/vivo-va-bacarat-1",
  //     imgUrl: "./images/Vivo/VABacarat1.jpg",
  //     name: "VA Bacarat 1",
  //     providerName: "Vivo",
  //   },
  //   VABacarat2: {
  //     redirectUrl: "/casino/vivo-va-bacarat-2",
  //     imgUrl: "./images/Vivo/VABacarat2.jpg",
  //     name: "VA Bacarat 2",
  //     providerName: "Vivo",
  //   },
  //   VABacarat3: {
  //     redirectUrl: "/casino/vivo-va-bacarat-3",
  //     imgUrl: "./images/Vivo/VABacarat3.jpg",
  //     name: "VA Bacarat 3",
  //     providerName: "Vivo",
  //   },
  //   VABacarat4: {
  //     redirectUrl: "/casino/vivo-va-bacarat-4",
  //     imgUrl: "./images/Vivo/VABacarat4.jpg",
  //     name: "VA Bacarat 4",
  //     providerName: "Vivo",
  //   },
  //   VABacarat5: {
  //     redirectUrl: "/casino/vivo-va-bacarat-5",
  //     imgUrl: "./images/Vivo/VABacarat5.jpg",
  //     name: "VA Bacarat 5",
  //     providerName: "Vivo",
  //   },
  // },

  netent: {
    BloodSuckers: {
      redirectUrl: "/casino/ezgne-blood-suckers",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img1.png",
      alt: "blood suckers",
      name: "Blood Suckers",
      providerName: "netent",
    },
    BloodSuckersII: {
      redirectUrl: "/casino/qtechnetent-blood-suckers-ii",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img2.png",
      alt: "blood suckers II",
      name: "Blood Suckers II",
      providerName: "netent",
    },
    DarkKingForbiddenRiches: {
      redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img3.png",
      alt: "dark king forbidden riches",
      name: "Dark King: Forbidden Riches",
      providerName: "netent",
    },
    DazzleMeMegaways: {
      redirectUrl: "/casino/ezgne-dazzle-me-megaways",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img4.png",
      alt: "dazzle me megaways",
      name: "Dazzle Me Megaways",
      providerName: "netent",
    },
    DeadOrAlive2FeatureBuyDazzleMeMegaways: {
      redirectUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img5.png",
      alt: "dead or alive 2 feature buy",
      name: "Dead or Alive 2 Feature Buy",
      providerName: "netent",
    },
    DivineFortuneMegaways: {
      redirectUrl: "/casino/ezgne-divine-fortune-megaways",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img6.png",
      alt: "divine fortune megaways",
      name: "Divine Fortune Megaways",
      providerName: "netent",
    },
    FinnsGoldenTavern: {
      redirectUrl: "/casino/qtechnetent-finns-golden-tavern",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img7.png",
      alt: "finn's golden tavern",
      name: "Finn's Golden Tavern",
      providerName: "netent",
    },
    FruitShop: {
      redirectUrl: "/casino/ezgne-fruit-shop",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img8.png",
      alt: "fruit shop",
      name: "Fruit Shop",
      providerName: "netent",
    },
    FruitShopChristmasEdition: {
      redirectUrl: "/casino/ezgne-fruit-shop-christmas-edition",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img9.png",
      alt: "fruit shop christmas edition",
      name: "Fruit Shop Christmas Edition",
      providerName: "netent",
    },
    FruitShopMegaways: {
      redirectUrl: "/casino/ezgne-fruit-shop-megaways",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img10.png",
      alt: "fruit shop megaways",
      name: "Fruit Shop Megaways",
      providerName: "netent",
    },
    GonzosQuest: {
      redirectUrl: "/casino/qtechnetent-gonzos-quest",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img11.png",
      alt: "gonzo's quest",
      name: "Gonzo's Quest",
      providerName: "netent",
    },
    JackHammer2FishyBusiness: {
      redirectUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img12.png",
      alt: "jack hammer 2: fishy business",
      name: "Jack Hammer 2: Fishy Business",
      providerName: "netent",
    },
    ParthenonQuestForImmortality: {
      redirectUrl: "/casino/qtechnetent-parthenon-quest-for-immortality",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img13.png",
      alt: "parthenon: quest for immortality",
      name: "Parthenon: Quest for Immortality",
      providerName: "netent",
    },
    PyramidQuestForImmortality: {
      redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img14.png",
      alt: "Pyramid: quest for immortality",
      name: "Pyramid: Quest for Immortality",
      providerName: "netent",
    },
    ReelRush: {
      redirectUrl: "/casino/ezgne-reel-rush",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img15.png",
      alt: "reel rush",
      name: "Reel Rush",
      providerName: "netent",
    },
    RichesOfMidgardLandAndExpand: {
      redirectUrl: "/casino/ezgne-riches-of-midgard-land-and-expand",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img16.png",
      alt: "riches of midgard: land and expand",
      name: "Riches of Midgard: Land and Expand",
      providerName: "netent",
    },
    RomeTheGoldenAge: {
      redirectUrl: "/casino/ezgne-rome-the-golden-age",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img17.png",
      alt: "rome: the golden age",
      name: "Rome: The Golden Age",
      providerName: "netent",
    },
    SecretsOfAtlantis: {
      redirectUrl: "/casino/ezgne-secrets-of-atlantis",
      code: "",
      casino: "Net Ent",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/netent/netent-img18.png",
      alt: "secrets of atlantis",
      name: "Secrets of Atlantis",
      providerName: "netent",
    },
  },

  redtiger: {
    Strike777: {
      redirectUrl: "/casino/ezgrt-777-strike",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img1.png",
      alt: "777 strike",
      name: "777 Strike",
      providerName: "redtiger",
    },
    ZeusLightningPowerReels: {
      redirectUrl: "/casino/ezgrt-zeus-lightning-power-reels",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img2.png",
      alt: "zeus lightning power reels",
      name: "Zeus Lightning Power Reels",
      providerName: "redtiger",
    },
    WingsOfRa: {
      redirectUrl: "/casino/ezgrt-wings-of-ra",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img3.png",
      alt: "wings of ra",
      name: "Wings of Ra",
      providerName: "redtiger",
    },
    AztecSpins: {
      redirectUrl: "/casino/ezgrt-aztec-spins",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img4.png",
      alt: "aztec spins",
      name: "Aztec Spins",
      providerName: "redtiger",
    },
    BountyRaid: {
      redirectUrl: "/casino/ezgrt-bounty-raid",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img5.png",
      alt: "bounty raid",
      name: "Bounty Raid",
      providerName: "redtiger",
    },
    DragonKingLegendOfTheSeas: {
      redirectUrl: "/casino/qtechredtiger-dragon-king-legend-of-the-seas",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img6.png",
      alt: "dragon king: legend of the seas",
      name: "Dragon King: Legend of the Seas",
      providerName: "redtiger",
    },
    DragonsFire: {
      redirectUrl: "/casino/ezgrt-dragons-fire",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img7.png",
      alt: "dragon's fire",
      name: "Dragon's Fire",
      providerName: "redtiger",
    },
    DragonsFireInfinireels: {
      redirectUrl: "/casino/qtechredtiger-dragons-fire-infinireels",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img8.png",
      alt: "dragon's fire infinireels",
      name: "Dragon's Fire INFINIREELS",
      providerName: "redtiger",
    },
    DragonsFireMegaways: {
      redirectUrl: "/casino/ezgrt-dragons-fire-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img9.png",
      alt: "dragon's fire megaways",
      name: "Dragon's Fire Megaways",
      providerName: "redtiger",
    },
    DragonsLuck: {
      redirectUrl: "/casino/ezgrt-dragons-luck",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img10.png",
      alt: "dragon's luck",
      name: "Dragon's Luck",
      providerName: "redtiger",
    },
    DragonsLuckDeluxe: {
      redirectUrl: "/casino/ezgrt-dragons-luck-deluxe",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img11.png",
      alt: "dragons luck deluxe",
      name: "Dragon's Luck Deluxe",
      providerName: "redtiger",
    },
    DragonsLuckMegaways: {
      redirectUrl: "/casino/qtechredtiger-dragons-luck-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img12.png",
      alt: "dragon's luck megaways",
      name: "Dragon's Luck Megaways",
      providerName: "redtiger",
    },
    DragonsLuckPowerReels: {
      redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img13.png",
      alt: "dragon's luck power reels",
      name: "Dragon's Luck Power Reels",
      providerName: "redtiger",
    },
    DynamiteRichesMegaways: {
      redirectUrl: "/casino/qtechredtiger-dynamite-riches-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img14.png",
      alt: "dynamite riches megaways",
      name: "Dynamite Riches Megaways",
      providerName: "redtiger",
    },
    FortuneHouse: {
      redirectUrl: "/casino/ezgrt-fortune-house",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img15.png",
      alt: "fortune house",
      name: "Fortune House",
      providerName: "redtiger",
    },
    GoldenCryptex: {
      redirectUrl: "/casino/ezgrt-golden-cryptex",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img16.png",
      alt: "golden cryptex",
      name: "Golden Cryptex",
      providerName: "redtiger",
    },
    GoldenOffer: {
      redirectUrl: "/casino/ezgrt-golden-offer",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img17.png",
      alt: "golden offer",
      name: "Golden Offer",
      providerName: "redtiger",
    },
    GonzosQuestMegaways: {
      redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
      code: "",
      casino: "Red Tiger",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/redtiger/redtiger-img18.png",
      alt: "gonzo's quest megaways",
      name: "Gonzo's Quest Megaways",
      providerName: "redtiger",
    },
  },
};
